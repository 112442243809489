<template>
  <HeaderPage image-class="articles page-header">
    <template #summary>
      <HeaderPageHeaderCopy slug="articles-header" />
    </template>
    <template #body>
      <transition name="fade">
        <keep-alive>
          <router-view />
        </keep-alive>
      </transition>
    </template>
  </HeaderPage>
</template>

<script>
import HeaderPage from "@/components/common/HeaderPage.vue";
import HeaderPageHeaderCopy from "@/components/resources/HeaderPageHeaderCopy.vue";

export default {
  name: "Articles",
  metaInfo: {
    title: "Articles - ",
    meta: [
      { vmid: "description", name: "description", content: "Feel inspired, learn something new, or find some help. Our articles have something to keep everyone writing." }
    ]
  },
  components: {
    HeaderPage,
    HeaderPageHeaderCopy
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/mixins';
.card-list {
  justify-content: space-between !important;
}
.card {
  padding: 0 !important;
  @include mobile {
    flex-direction: column !important;
  }
}
header {
  background-image: url('/img/banners/Articles_Mobile.jpg');
  @include tablet {
    background-image: url('/img/banners/Articles_Tablet.jpg');
  }  
  @include desktop {
    background-image: url('/img/banners/Articles_Desktop.jpg');
  }  
  @include widescreen {
    background-image: url('/img/banners/Articles_Huge.jpg');
  }
}
</style>
